import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Grid, Row, Col } from "react-flexbox-grid"
import "typeface-playfair-display"
import Img from "gatsby-image"
import style from "./home.module.css"
import GridGenerator from "../components/gridGenerator"

const MembershipInfo = ({data}) => {
  return (
    <Layout>
      <SEO title="Home" keywords={[`gatsby`, `application`, `react`]} />
      <div style={{marginBottom: 40, marginLeft: 'auto', marginRight: 'auto', maxWidth: 560}}>
        <a href="https://app.dietrioters.com/register">
          <Img fluid={data.membershipPic.childImageSharp.fluid} />
        </a>
      </div>

      <p className={style.openingText}>Interested in becoming a <a href="https://app.dietrioters.com/register">diet rioter member</a>? Wondering if it's for you?</p>

      <Row style={{marginTop: 75, marginBottom: 75}} className={style.membershipForYou}>
        <Col sm={12} md={12} lg={6}>
      <p style={{fontWeight: 'bold'}}>This membership is for you if:</p>
      <ul>
        <li>you're ready to stop dieting</li>

      <li>you're ready to find food freedom</li>

      <li>you're ready to take the steps and do the work to learn and grow in your intuitive eating journey</li>

        <li>you love connecting with other diet rioters doing the same</li>
      </ul>
    </Col>

    <Col sm={12} md={12} lg={6}>
      <p style={{fontWeight: 'bold'}}>The membership is NOT for you if:</p>
      <ul>
      <li>you're currently struggling with an active eating disorder</li>

      <li> you're wanting a quick fix, weight loss tool or someone to tell you exactly what and how to eat</li>

      <li> you're needing medical attention and one on one medical care</li>

      <li> you're not willing to try the exercises and do the internal work to make lasting change</li>
    </ul>
  </Col>
</Row>

<Row style={{marginBottom: 45}}>
  <Col sm={12} md={12} lg={6}>
    <div className={style.monthlyPriceBox}>
      <h3 className={style.subscriptionTitle}>Monthly</h3>
      <span className={style.subscriptionPrice}>$59</span>
      <span className={style.subscriptionTime}>/month</span>
      <span className={style.subscriptionTime}>minimum 3 months</span>
      <span className={style.subscriptionSeparator}></span>

      <ul className={style.featureList}>
        <li>Welcome to Diet Rioters Membership intro course</li>
        <li>Daily affirmation texts from Brooke and Alyssa</li>
        <li>Weekly prompts</li>
        <li>Monthly live Strategy Calls</li>
        <li>Monthly live Q &amp; A calls</li>
        <li>Quarterly mini-courses</li>
        <li>Monthly member only giveaways</li>
        <li className={style.notIncluded}>2 months for free</li>
        <li className={style.notIncluded}>access to community chat on voxer</li>
        <li className={style.notIncluded}>Access to our $99 intuitive eating course for free</li>
        <li className={style.notIncluded}>Diet Rioters merch shipped to you upon registration (US only)</li>
        <li className={style.notIncluded}>4 live guest speaker events annually</li>
      </ul>
      <a className={style.buttonPrimaryJoin} href="https://app.dietrioters.com/register">Join Now!</a>
    </div>
  </Col>
  <Col sm={12} md={12} lg={6}>
    <div className={style.yearlyPriceBox}>
      <h3 className={style.subscriptionTitle}>Yearly</h3>
      <span className={style.subscriptionPrice}>$590</span>
      <span className={style.subscriptionTime}>/year</span>
      <span className={style.subscriptionTime}>payment plan available</span>
      <span className={style.subscriptionSeparator}></span>

      <ul className={style.featureList}>
        <li>Welcome to Diet Rioters Membership intro course</li>
        <li>Daily affirmation texts from Brooke and Alyssa</li>
        <li>Weekly prompts</li>
        <li>Monthly live Strategy Calls</li>
        <li>Monthly live Q &amp; A calls</li>
        <li>Quarterly mini-courses</li>
        <li>Monthly member only giveaways</li>
        <li>2 months for free</li>
        <li>access to community chat on voxer</li>
        <li>Access to our $99 intuitive eating course for free</li>
        <li>Diet Rioters merch shipped to you upon registration (US only)</li>
        <li>4 live guest speaker events annually</li>
      </ul>
      <a className={style.buttonPrimaryJoin} href="https://app.dietrioters.com/register?product=yearly">Join Now!</a>
    </div>
  </Col>
</Row>


<div className={style.whatToExpect}>
    <p style={{fontWeight: 'bold'}}>Inside the membership you can expect:</p>
    <ol>
      <li> Weekly action steps/tips/tricks to work on in your own time that will get you thinking and pushing you forward in your intuitive eating journey</li>

      <li>Recipes to try out and get you having fun with food again</li>

      <li> Monthly LIVE member only Q and A calls with Brooke and Alyssa answering your questions in depth and breaking down those barriers that are holding you back</li>

      <li> Quarterly mini-courses designed to teach you how to tackle and overcome a common barrier in the intuitive eating journey - a lot of times these will be based on what we keep hearing from our members!</li>

      <li> Community, community, community! You will find support from your fellow diet rioters as well as Brooke and Alyssa in the comments inside the membership, on our close friends list on Instagram and through email! We are all about connection and walking this road together not alone!</li>
    </ol>
  </div>


    <p>Hope to see you on the inside diet rioter! If you have any questions or concerns please do not hesitate to reach out by emailing hello@dietriotpodcast.com</p>


    <p>Need more info? Check out our <a target="_blank" href="https://www.instagram.com/tv/CCCuhEipG0u/" >IGTV where we answered a TON of questions</a> about the membership or our <a target="_blank" href="https://podcasts.apple.com/us/podcast/i-need-a-nap-all-about-diet-rioters-membership/id1439663856?i=1000480304101">podcast episode</a> where we went into detail about what to expect!</p>


    <p>Also want a taste of what to expect inside the mini-courses? Check out our <a target="_blank" href="https://www.instagram.com/p/CCL9UqsJQQk/">FREE mini-training on how to stop mindlessly eating and start mindfully eating</a> we did on instagram that is a mini-version of what you will see!</p>


    <p>See you soon Diet Rioters!</p>

    <a className="button-primary" href="https://app.dietrioters.com/register">Join Now!</a>

    </Layout>);
};

export default MembershipInfo;

export const query = graphql`
  query {
    membershipPic: file(relativePath: { eq: "membership-info.png" }) {
      childImageSharp {
        fluid(maxWidth: 564) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

